import Consts from '../../consts/Consts';
import PageTitles from '../../consts/PageTitles';
import Routes from '../../consts/Routes';
import EnvConfigs from '../../Configurations';
import { ExternalLink } from '@livingdesign/icons';
import Config from '../../utils/Config';
import UserType from '../../user/UserType';

export const AdditionalToolsNav = isHolidayReadinessMFEEnabled => {
  return [
    {
      path: Consts.EMPTY,
      url: Routes.HOLIDAY_READINESS,
      isMFE: isHolidayReadinessMFEEnabled,
      disabled: !isHolidayReadinessMFEEnabled,
      label: PageTitles.ADDITIONAL_TOOLS,
      ignoreBreadcrumb: true,
      isBreadcrumbEnabled: false,
      supportsLiteMode: true,
      childPages: isHolidayReadinessMFEEnabled
        ? [
            {
              path: 'dl/DistributionList',
              url: EnvConfigs.REACT_APP_GUIDE_GROUPS_URL,
              isNewTab: true,
              label: PageTitles.DISTRIBUTION_LIST,
              disabled: !Config.isDLEnabled || UserType.isStoreAssociate(),
              leadingIcon: <ExternalLink />,
            },
            {
              path: 'holidayreadiness/holidayReadiness',
              url: `${Routes.HOLIDAY_READINESS}`,
              label: PageTitles.HOLIDAY_READINESS,
              supportsLiteMode: true,
              disabled: !isHolidayReadinessMFEEnabled,
            },
          ]
        : null,
    },
  ];
};
