import Consts from '../../../consts/Consts';
import PageTitles from '../../../consts/PageTitles';
import Routes from '../../../consts/Routes';
import { HardwareOrderingPages } from './HardwareOrderingPages';
import { MobileOrderingPages } from './MobileOrderingPages';
import Config from '../../../utils/Config';

export const orderingPages = (isOrderingMFEEnabled, isIDCMobileDisabled) => {
  return [
    {
      path: Consts.EMPTY,
      url: Routes.ORDERING,
      isMFE: isOrderingMFEEnabled,
      disabled: !isOrderingMFEEnabled,
      label: PageTitles.ORDERING,
      ignoreBreadcrumb: true,
      isBreadcrumbEnabled: false,
      supportsLiteMode: true,
      childPages: isOrderingMFEEnabled
        ? [
            ...HardwareOrderingPages(),
            ...MobileOrderingPages(isIDCMobileDisabled),
            {
              path: 'vdi/VDIs',
              url: Routes.VDI,
              label: PageTitles.VDI,
              disabled: !Config.isVDIEnabled,
            },
          ]
        : null,
    },
  ];
};
