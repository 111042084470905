import React, { useContext, useEffect, useState } from 'react';
import Consts from '../../consts/Consts';
import CSS from '../../consts/CSS';
import PageHeader from './PageHeader';
import { trackPage } from '../../utils/appInsight';
import { useLocation, withRouter } from 'react-router-dom';
import ButtonContainer from '../../components/layout/ButtonContainer';
import AppContext from '../../app/context/AppContext';
import Pages from '../../consts/Pages';
import Errors from '../../consts/Errors';
import MTLink from '../../components/button/MTLink';
import { ReactComponent as QuestionBox } from '../../assets/svg/questionBox.svg';
import MTBackButton from '../button/MTBackButton';
import LDBreadcrumb from '../../components/breadcrumb/LDBreadcrumb';
import Config from '../../utils/Config';
import AlertBanner from './AlertBanner';
import Routes from '../../consts/Routes';

const Page = ({
  icon,
  title,
  trackingTitle, //Pass to track a title, but not show one in <PageHeader>
  subTitle,
  children,
  className,
  large = false,
  medium = false,
  showBackButton = false,
  includeErrorIcon = false,
  prePageChildren,
  setShowInfoDialog = null,
  reduceMarginBottom = false,
  increaseMarginTop = false,
  pageHeaderAsBanner = false,
  bannerProps,
  svgImage,
  mobileSvgImage,
  leftAlignHeader = false,
  flex = false,
  showHeader = true,
  alerts = [],
  alertAutoplaySpeed,
  ...props
}) => {
  const location = useLocation();
  const { isOnInitialRoute } = useContext(AppContext);
  const isLicenseOnboardRoute = location.pathname.includes(
    `${Routes.APPS}${Routes.ONBOARD_LICENSE}`
  );

  const { isInLiteMode, enabledMFEs } = useContext(AppContext);
  const [supportsLiteMode, setSupportsLiteMode] = useState(null);
  const showPageUnavailable = isInLiteMode && supportsLiteMode === false;
  const { match } = props;
  useEffect(() => {
    trackPage({
      name: title
        ? `${title} - ${location.pathname}`
        : trackingTitle
        ? `${trackingTitle} - ${location.pathname}`
        : location.pathname,
    });

    setSupportsLiteMode(Pages.supportsLiteMode(match.path, enabledMFEs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAlertBanner = Config.isAlertBannerEnabled && alerts;

  const isSAT = location.pathname == Routes.TECHSUPPORT;
  const isLicenses = location.pathname == Routes.LICENSES;

  return (
    <>
      {Config.isBreadcrumbEnabled && !isSAT && !isLicenses && (
        <div>
          <LDBreadcrumb
            currentRoute={match.path}
            currentURL={match.url}
            currentParams={match.params}
          />
        </div>
      )}
      <div className={`page${className ? ` ${className}` : Consts.EMPTY}`}>
        {includeErrorIcon ? (
          <div className="error-icon-wrapper">
            <QuestionBox />
          </div>
        ) : null}
        {prePageChildren}
        {showHeader && (
          <PageHeader
            icon={icon}
            title={
              showPageUnavailable
                ? Errors.LITE_MODE_UNSUPPORTED_ROUTE
                : title || trackingTitle
            }
            subTitle={
              showPageUnavailable
                ? Errors.LITE_MODE_ACTIVE_WE_DOWN_SUB_TEXT
                : subTitle
            }
            titleForTrackingOnly={
              !showPageUnavailable && trackingTitle ? true : false
            }
            setShowInfoDialog={setShowInfoDialog}
            reduceMarginBottom={reduceMarginBottom}
            increaseMarginTop={increaseMarginTop}
            pageHeaderAsBanner={pageHeaderAsBanner}
            svgImage={svgImage}
            mobileSvgImage={mobileSvgImage}
            leftAlignHeader={leftAlignHeader}
            bannerProps={bannerProps}
          />
        )}
        {showAlertBanner ? (
          <AlertBanner
            alertAutoplaySpeed={alertAutoplaySpeed}
            alerts={alerts}
          />
        ) : null}
        {!showPageUnavailable ? (
          <>
            {children}
            {showBackButton && !isOnInitialRoute ? (
              <ButtonContainer>
                <MTBackButton />
              </ButtonContainer>
            ) : null}
          </>
        ) : (
          <div className="error-link-wrapper">
            <MTLink
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh
            </MTLink>
          </div>
        )}
        <style jsx>
          {`
            .page {
              flex-basis: 100%;
              padding: ${isLicenseOnboardRoute
                ? '0px'
                : `10px ${CSS.PAGE_PADDING_LEFT_RIGHT} 30px`};
              max-width: ${isLicenseOnboardRoute
                ? '100%'
                : large
                ? CSS.PAGE_CONTENT_MAX_WIDTH_LARGE
                : medium
                ? CSS.PAGE_CONTENT_MAX_WIDTH_MEDIUM
                : CSS.PAGE_CONTENT_MAX_WIDTH};
              margin: 0 auto;
              box-sizing: border-box;
            }

            .error-icon-wrapper {
              width: 100%;
              display: flex;
              justify-content: center;
              margin-top: 100px;
            }

            .error-link-wrapper {
              width: 100%;
              display: inline-block;
              text-align: center;
              font-size: 20px;
              margin-bottom: 20px;
            }

            .error-link-wrapper a {
              padding: 0 10px;
            }
          `}
        </style>
      </div>
    </>
  );
};

export default withRouter(Page);
