const PageTitles = {
  HOMEPAGE: 'Home',
  DEVICES: 'My Devices',
  DEVICES_PHYSICAL_TAB: 'Physical Devices',
  DEVICES_VIRTUAL_TAB: 'Virtual Devices',
  SECURITY: 'Password and Account',
  ORDERS: 'My Orders',
  MOBILE: 'Corporate Devices',
  DISTRIBUTION_LIST: 'Distribution List',
  TECH_SUPPORT: 'Support',
  HELP_CENTER: 'Help Center',
  SHIPMENT_TRACKER: 'Hardware Shipment Tracker',
  SIM: 'SIM Cards',
  ADMIN: 'Admin Site',
  //APPS: 'Apps and Licenses',
  APPS: 'Applications',
  LICENSES: 'Licenses',
  MYAPPS: 'Apps and Licenses',
  ADDITIONAL_TOOLS: 'Additional Tools',
  APPSTORE: 'App Store',
  APPSTORE_APPS: 'Apps',
  LICENSE_ONBOARDING: 'License Onboarding',
  APPREQUESTS: 'Requests',
  APPAPPROVALS: 'App Approvals',
  APPLINKS: 'More Links',
  APPMANAGEMENT: 'App Management',
  ORDERING: 'Devices',
  ORDERING_HARDWARE: 'Accessories',
  ONBOARD_LICENSE: 'Onboard Your License',
  MANAGER_TOOLS: 'Manager Tools',
  NEW_HIRE: 'Get Started',
  MAC_OR_PC: 'Which device would you like to set up?',
  NEW_HIRE_SECURITY_PASSWORD_STRENGTH: 'Next, let’s change your password',
  NEW_HIRE_SECURITY_SETTINGS: 'Last, let’s update your security settings',
  NEW_HIRE_GALLERY: 'Hints & Tips',
  NEW_HIRE_ARTICLE_CISCO_ANYCONNECT: 'Cisco AnyConnect',
  USER_LOOKUP: 'User Lookup',
  VDI: 'Virtual Desktop',
  VDI_REQUEST: 'Request a VDI',
  VDI_REQUEST_COMPLETION: 'Request successful',
  ORDER_CORPORATE_DEVICE_TITLE: 'Order Corporate Device',
  ORDER_LOANER_DEVICE_TITLE: 'International Loaner Phone',
  ORDER_HOTSPOT_DEVICE_TITLE: 'Order Hotspot Device',
  ORDER_EAGLE_HOME_DEVICE: 'Order Eagle@Home device',
  INTERNATIONAL_DATA_PLAN_ANOTHER:
    'Add International Data Plan for Another Associate',
  INTERNATIONAL_DATA_PLAN_MYSELF: 'Add International Data Plan',
  CANCEL_CORPORATE_DEVICE_MYSELF: 'Cancel my Corporate Mobile Service',
  CANCEL_CORPORATE_DEVICE_ANOTHER:
    'Cancel Corporate Mobile Service for Another Associate',
  SELECT_PERSONA: 'Who is this device for?',
  HELP_CENTER: 'Help Center',
  HELP_CENTER_CALL_US: 'Call Us',
  HELP_CENTER_TALK_TO_EXPERT: 'Talk to an Expert',
  TICKETS: 'Tickets',
  TICKETS_SUBMIT: 'Submit a Ticket',
  TOPIC_WINDOWS: 'Windows PC',
  TOPIC_MAC: 'Mac',
  TOPIC_MOBILE: 'Mobile',
  TOPIC_VDI: 'VDI',
  TOPIC_CONFERENCE_COLLABORATION_TOOLS: 'Conference Collaboration Tools',
  TOPIC_EAGLE_AT_HOME: 'Eagle @Home',
  TOPIC_RETURN_TO_OFFICE: 'Return to Office',
  TOPIC_WAYS_OF_WORKING: 'Ways of Working',
  TOPIC_HOLIDAY_READINESS: 'Holiday Readiness',
  TOPIC_WORK_FROM_HOME: 'Work from Home',
  TOPIC_WORK_IN_OFFICE: 'Working in Office',
  ARTICLE_TECH_SUPPORT: 'Tech Support',
  ARTICLE_TECH_BAR: 'Tech Bar',
  ARTICLE_LINKS: 'Important Links | Access Request',
  ARTICLE_CONTACTS: 'Important Contacts List',
  ARTICLE_SHAREPOINT_GENERAL: 'SharePoint - General',
  ARTICLE_SHAREPOINT_SHARING: 'SharePoint - External Sharing',
  ARTICLE_SHAREPOINT_PERMISSIONS: 'SharePoint - Permissions',
  ARTICLE_SHAREPOINT_MOBILE: 'SharePoint - Mobile',
  ARTICLE_SHAREPOINT_RECOVER: 'SharePoint - Recover Deleted Content',
  ARTICLE_ONEDRIVE_ABOUT: 'OneDrive - What is OneDrive?',
  ARTICLE_ONEDRIVE_TIPS: 'OneDrive - OneDrive Tips',
  ARTICLE_ONEDRIVE_VS_SHAREPOINT: 'OneDrive - OneDrive vs. SharePoint',
  ARTICLE_ONEDRIVE_RECOVER: 'OneDrive - Recover Deleted Content',
  ARTICLE_ONEDRIVE_RESTORE: 'OneDrive - Site Restoration',
  ARTICLE_ONEDRIVE_SIGN_IN: 'OneDrive - Sign In',
  ARTICLE_ONEDRIVE_SHARING: 'OneDrive - Sharing',
  ARTICLE_ONEDRIVE_SYNC_MAC: 'OneDrive - Sync Client for Mac',
  ARTICLE_OFFICE_PROMPTS: 'Office Prompts',
  ARTICLE_OUTLOOK_ZOOM: 'Outlook - Zoom Plugin',
  ARTICLE_OUTLOOK_LOGIN: 'Outlook - Outlook Login',
  ARTICLE_OUTLOOK_MAP_MAILBOX_MAC: 'Outlook - Map Additional Mailbox',
  ARTICLE_OUTLOOK_MAP_MAILBOX_WINDOWS: 'Outlook - Map Additional Mailbox',
  ARTICLE_OUTLOOK_NOT_SYNCING: 'Outlook - Outlook not syncing',
  ARTICLE_CONNECTIVITY_WFH: 'WFH connection issues',
  ARTICLE_CONNECTIVITY_ETHERNET:
    'Connecting devices to your home router using an Ethernet cable',
  ARTICLE_CONNECTIVITY_VPN: 'How To VPN',
  ARTICLE_CONNECTIVITY_WIFI_ISSUE_WINDOWS: 'Wi-Fi connection issues in Windows',
  ARTICLE_CONNECTIVITY_VPN_ISSUE_WINDOWS: 'Cisco AnyConnect (VPN) issues',
  ARTICLE_PASSWORD_CHANGE_MAC: 'Change Password',
  ARTICLE_PASSWORD_CHANGE_WINDOWS: 'Change Password',
  ARTICLE_PASSWORD_SSPR: 'SSPR',
  ARTICLE_PASSWORD_REQUIRMENTS: 'Password Requirement',
  ARTICLE_PASSWORD_EXPIRED_WINDOWS: 'Expired Password',
  ARTICLE_PASSWORD_SSPR_FAQS: 'Self Service Password Reset (SSPR) FAQs',
  ARTICLE_CHROME_CACHE: 'Clear Cache and Cookies',
  ARTICLE_CHROME_DEFAULT_WINDOWS: 'Default Browser',
  ARTICLE_THIRD_PARTY_TABLEAU: 'How to get Access | Tableau',
  ARTICLE_WINDOWS_COMPUTER_NAME: 'Computer Name',
  ARTICLE_WINDOWS_BITLOCKER: 'Bitlocker',
  ARTICLE_WINDOWS_MAP_PRINTER: 'Map Printer to the new server',
  ARTICLE_WINDOWS_MAP_DRIVE: 'Map Shared Network Drive',
  ARTICLE_MAC_SELF_SERVICE: 'Self-Service',
  ARTICLE_MAC_MAP_PRINTER: 'Map a Printer',
  ARTICLE_MAC_ADMIN: 'Mac Admin',
  ARTICLE_2FA_YUBIKEY: 'Yubikey',
  ARTICLE_2FA_YUBIKEY_ERRORS: 'Yubikey Logon Errors',
  ARTICLE_2FA_YUBIKEY_ENROLLMENT: 'Yubikey Enrollment',
  ARTICLE_2FA_SOFT_TOKEN: 'Enroll Soft Token | Google Authenticator',
  ARTICLE_2FA_VPN: 'Connecting remotely with VPN',
  ARTICLE_2FA_SETUP: 'Setup | 2-Step Verification',
  ARTICLE_2FA_FAQS: '2FA FAQs',
  ARTICLE_MOBILE_SETUP: 'Corporate Phone Setup',
  ARTICLE_ZOOM_ROOM: 'Zoom Room',
  ARTICLE_SCHEDULE_MEETING: 'Schedule Meeting Room from Outlook',
  SSPR_SUPPORT: 'SSPR Registration Status',
  TEAMS_VOICE_AND_VIDEO: 'Microsoft Teams tips and more',
  TOPIC_MICROSOFT_TEAMS: 'Microsoft Teams',
  TOPIC_ZOOM: 'Zoom',
  TOPIC_WORKPLACE: 'Workplace',
  TOPIC_VIDEO_CONFERENCING: 'Video Conferencing',
  ACADEMY_VIDEO_CONFERENCING: 'Academy Video Conferencing',
  HEALTH_VIDEO_CONFERENCING: 'WM Health Video Conferencing',
  MSTEAMS_INSTALL_ON_WINDOWS: 'Install on Windows',
  MSTEAMS_LOOP_COMPONENTS: 'Loop Components',
  MSTEAMS_MEETING_SCHEDULING: 'Meeting/Scheduling Issues',
  MSTEAMS_NETWORK_LATENCY: 'Network Latency',
  MSTEAMS_VIDEO_ISSUES: 'Video Issues',
  MSTEAMS_LOGIN_ISSUE: 'Login Issues',
  MSTEAMS_MOBILE_APP: 'Mobile App',
  MSTEAMS_APPLICATION_ISSUES: 'Application Issues',
  MSTEAMS_AUDIO_ISSUES: 'Audio Issues',
  ZOOM_OUTLOOK_SCHEDULING_ISSUES: 'Outlook Scheduling Issues',
  ZOOM_FREEZE: 'Zoom Freezes when Screen Sharing',
  ZOOM_NETWORK: 'Network/Stability',
  ZOOM_DISPLAY_CHECKIN: 'Scheduling Display Check-in Issues',
  ZOOM_ACCOUNT: 'Account Issue',
  ZOOM_ACCOUNT_ACTIVATION: ' Account Activation',
  ZOOM_MOBILE_ISSUES: 'Mobile Issues',
  ZOOM_RECORDING: 'Recording',
  VC_DEVICE_NETWORK_ISSUES: 'Device/Network Issues',
  VC_DGJ_CAPABILITIES: 'Direct Guest Join (DGJ) Capabilities',
  VC_GENERAL_ISSUES: 'MS Teams Rooms - General Issues',
  VC_PRESENTATION_ISSUES: 'Presentation Issues',
  VC_TOUCH_PANEL_PAIRING: 'Touch Panel Pairing',
  VC_TV_ISSUES: 'TV Issues',
  VC_VIDEO_CONFERENCING: 'Video Conferencing Provisioning',
  VC_ROOM_SCHEDULING: 'Room Scheduling',
  VC_AUDIO_ISSUES: 'Audio Issues',
  VC_CAMERA_ISSUES: 'Camera Issues',
  AVC_CISCO_PANEL_PAIRING: 'Cisco Touch Panel Pairing Issues',
  AVC_DAMAGED_DEFECTIVE_DEVICES: 'Damaged/Defective Devices',
  WP_DESKTOP: 'Desktop Issues',
  WP_MOBILE_LOGIN: 'Mobile Login',
  HOLIDAY_READINESS: 'Holiday Readiness',
  DOS_AND_DONTS_FOR_ROOM_BOOKING: "Dos and Don'ts for Room Booking",
  HELPFUL_LINKS: 'Helpful Links',
  ADMIN_ACCESS_REQUEST: 'Admin Access / Request',
  PRINT_SUPPORT: 'Print Support',
  PASSWORD_AND_ACCOUNT: 'Password and Account',
  CLOUD_STORAGE: 'Cloud Storage',
  CONNECTIVITY: 'Connectivity',
  MESSAGING_AND_EMAIL: 'Messaging and Email',
  FIND_MY_WIN: 'Find My Win Number',
  CHANGE_OUTLOOK_INFO: 'Change Outlook Info',
  WORKDAY: 'Workday',
  ONE_WALMART: 'One Walmart',
  OFFICE_TOOLS: 'Office Tools',
  USER_PROFILE_AND_AD_GROUPS: 'User Profile and AD Groups (Wmlink/whois)',
  HOW_TO_USE_WMLINKS: 'How to use WMLinks',
  SOFTWARE_INSTALL: 'Software Install',
  ELEVATED_ACCESS: 'Elevated Access',
  DOWLOAD_RIGHT: 'Download Right',
  TERADATA_ALTERYX_POWERBI_TABLEAU: 'Teradata/Alteryx/Power BI/Tableau',
  HOST_ACCESS: 'HOST Access',
  LOCAL_ADMIN: 'Local Admin',
  ORDER_EQUIPMENT: 'Order Equipment',
  MAP_NETWORK_PRINTER_VIDEO: 'Map Network Printer - Video',
  MAP_NETWORK_PRINTER_DOC: 'Map Network Printer - Document',
  PASSWORD_RESET: 'Password Reset (SSPR)',
  CHANGE_YOUR_PASSWORD_VIDEO: 'Change Your Password - Video',
  CHANGE_YOUR_PASSWORD_DOC: 'Change Your Password - Document',
  TWOFA: '2FA',
  SOFT_TOKEN: 'Soft Token (Google Authenticator)',
  YUBIKEY_ENROLLMENT: 'Yubikey Enrollment',
  YUBIKEY_ORDER: 'Yubikey Order',
  ONE_DRIVE: 'One Drive',
  ONE_DRIVE_VIDEO_WALKTHROUGH: 'OneDrive Video Walkthrough',
  VIDEO: 'Video',
  SHAREPOINT: 'SharePoint',
  VPN: 'VPN',
  EAGLE_VIDEO: 'Eagle Video',
  GETTING_STARTED_MAC: 'Getting Started',
  MAC_WORKPLACE_CHANNEL: 'Mac Workplace Channel',
  MAC_FREQ_QUESTIONS: 'Frequently Ask Questions',
  MAC_TEAM_BLOG: 'Mac Team Blog',
  WINDOWS_ASK_QUESTIONS: 'Windows Q&A',
  WINDOWS_HELLO_BUSINESS: 'Windows Hello for Business',
  WINDOWS_WORKSPACE: 'Windows Workspace',
  WINDOWS_INSIDER_PROGRAM: 'Windows Insider Program',
};

export default PageTitles;

export const PageSubtitles = {
  SECURITY:
    'Enroll and manage your security information to prevent data breaches and to work from anywhere.',
};
