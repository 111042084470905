import { Divider, Heading } from '@walmart-web/livingdesign-components';
import MTSideNavigationItem from './MTSideNavigationItem';

const customStyles = {
  headingStyle: {
    marginLeft: '10px',
    marginTop: '8px',
  },
};

const MTSideNavigationItemParent = ({ label, childPages }) => {
  return (
    <>
      <Divider title={`Entering ${label} divider`} />
      <Heading
        key={label}
        size="small"
        UNSAFE_style={customStyles.headingStyle}
      >
        {label}
      </Heading>
      {childPages?.map(page => {
        return (
          <MTSideNavigationItem
            key={page.props.label}
            label={page.props.label}
            url={page.props.url}
            isNewTab={page.props.isNewTab}
            leadingIcon={page.props.leadingIcon}
          />
        );
      })}
      <Divider title={`Exiting ${label} divider`} />
    </>
  );
};

export default MTSideNavigationItemParent;
