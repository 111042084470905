import Consts from '../../../consts/Consts';
import PageTitles from '../../../consts/PageTitles';
import Routes from '../../../consts/Routes';
import Config from '../../../utils/Config';

export const appstorePages = isMFEEnabled => {
  return [
    {
      // Dev Note: prevents secondary page loading under MFE pages
      // Should maybe see if this needs added as a child?
      path: Consts.EMPTY,
      url: Routes.APPSPAGE,
      breadcrumbLabel: Consts.ROUTE_PARAM,
      disabled: !isMFEEnabled,
      isBreadcrumbEnabled: false,
    },
    {
      // License page
      path: Consts.EMPTY,
      url: Routes.LICENSES,
      breadcrumbLabel: PageTitles.LICENSES,
      disabled: !isMFEEnabled,
      isBreadcrumbEnabled: false,
    },
    {
      // License settings pages
      path: Consts.EMPTY,
      url: Routes.LICENSE_SETTINGS,
      breadcrumbLabel: Consts.ROUTE_PARAM,
      disabled: !isMFEEnabled,
      isBreadcrumbEnabled: false,
    },
    {
      path: isMFEEnabled ? 'appstore/AppStore' : Consts.EMPTY,
      url: Routes.APPS,
      isMFE: isMFEEnabled,
      disabled: !isMFEEnabled,
      label: isMFEEnabled ? PageTitles.APPS : Consts.EMPTY, // Removes
      ignoreBreadcrumb: true,
      isBreadcrumbEnabled: false,
      supportsLiteMode: true,
      redirect: !isMFEEnabled ? { url: Routes.APPS } : null, //Something is pointing to /app, instead of /apps
      childPages: isMFEEnabled
        ? [
            {
              path: 'appstore/AppStore',
              url: Routes.APPS,
              label: PageTitles.APPSTORE_APPS,
              supportsLiteMode: true,
            },
            {
              path: 'appstore/AppStore',
              url: Routes.LICENSES,
              label: PageTitles.LICENSES,
              supportsLiteMode: true,
              disabled: !Config.isMyLicensesTabEnabled,
            },
            {
              path: 'licenses/OnboardLicense',
              url: Routes.ONBOARD_LICENSE,
              label: PageTitles.LICENSE_ONBOARDING,
              disabled: !Config.isProductOnboardingFormEnabled, //Current onboard form live in prod
              supportsLiteMode: true,
            },
            /**New onbaording form not in production */
            {
              path: 'appstore/Onboarding',
              url: `${Routes.APPS}${Routes.ONBOARD_LICENSE}`,
              label: PageTitles.LICENSE_ONBOARDING,
              supportsLiteMode: true,
              disabled: !Config.isLicenseOnboardingEnabled,
            },
          ]
        : null,
    },
  ];
};
