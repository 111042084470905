import PageTitles from '../../../consts/PageTitles';
import Routes from '../../../consts/Routes';
import Config from '../../../utils/Config';
import UserType from '../../../user/UserType';

export const HardwareOrderingPages = () => {
  return [
    {
      path: 'ordering/Ordering',
      url: `${Routes.HARDWARE_ORDERING}`,
      label: PageTitles.ORDERING_HARDWARE,
      supportsLiteMode: true,
      disabled: !(Config.isHardwareOrderingEnabled || UserType.isUS()),
    },
    {
      path: 'ordering/Ordering',
      url: `${Routes.HARDWARE_ORDERING}/${Routes.HARWARE_ORDERING_LAPTOP}`,
      ignoreNavLabel: true,
      label: 'Laptop ordering',
      supportsLiteMode: true,
      disabled: !Config.isLaptopOrderingEnabled,
    },
    {
      path: 'ordering/Ordering',
      url: `${Routes.HARDWARE_ORDERING}/${Routes.HARWARE_ORDERING_ASSISTIVE_TECH}`,
      ignoreNavLabel: true,
      label: 'Assistive tech ordering',
      supportsLiteMode: true,
      disabled: !Config.isAssistiveTechOrderingEnabled,
    },
  ];
};
