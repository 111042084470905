import Consts from '../../consts/Consts';
import PageTitles from '../../consts/PageTitles';
import Routes from '../../consts/Routes';
import Config from '../../utils/Config';
import { getIsTechSupportEnabled } from '../../utils/helpers';

export const supportPages = () => {
  const isTechSupportEnabled = getIsTechSupportEnabled();
  return [
    {
      path: 'submitaticket/submitaticket',
      url: isTechSupportEnabled ? Routes.TECHSUPPORT : '/support', //URL needs to be /support for redirection to work,
      isMFE: true,
      label: PageTitles.TECH_SUPPORT,
      ignoreBreadcrumb: true,
      breadcrumbLabel: Consts.ROUTE_PARAM,
      isBreadcrumbEnabled: false,
      supportsLiteMode: true,
      redirect: isTechSupportEnabled && [
        {
          url: Routes.TICKETS_SUBMIT,
        },
        {
          url: '/helpcenter/tickets/submit',
        },
      ],
      childPages: [
        {
          path: 'submitaticket/submitaticket',
          url: Routes.TECHSUPPORT,
          label: PageTitles.ARTICLE_TECH_SUPPORT,
          disabled: !isTechSupportEnabled,
        },
        {
          path: Config.isSecurityDashboardV2Enabled
            ? 'securityV2/Security'
            : 'security/Security',
          url: Routes.SECURITY,
          redirect: { url: `${Routes.SECURITY}${Consts.ALL_FOLDERS}` },
          label: PageTitles.SECURITY,
          disabled: !Config.isSecurityAccessEnabled,
          supportsLiteMode: true,
        },
        {
          path: 'helpcenter/topics/TopicHybridMeetings',
          url: Routes.TOPIC_CONFERENCE_COLLABORATION_TOOLS,
          label: PageTitles.TOPIC_CONFERENCE_COLLABORATION_TOOLS,
          redirect: {
            // Soft Redirect as users knew this brand previously as hybrid meetingss
            url: Routes.TOPIC_HYBRID_MEETINGS,
            to: Routes.TOPIC_CONFERENCE_COLLABORATION_TOOLS,
            exact: true,
          },
          supportsLiteMode: true,
        },
      ],
    },
  ];
};
