import Consts from '../consts/Consts';
import { calculateTimeoutSeconds } from './calculateTimeoutSeconds';
import EnvConfigs from '../Configurations';
import Routes from '../consts/Routes';
import axios from 'axios';
import ErrorHandler from './ErrorHandler';
import Events from '../consts/Events';
import { trackEvent } from './appInsight';
import APIs from '../consts/APIs';
import UserType from '../user/UserType';
import Messages from '../consts/Messages';
import { fetchDigitalTwinTechBarLocations } from '../../src/pages/helpcenter/techBarLocations/utils/fetchTechBarLocations';
var states = require('us-state-codes');
var parser = require('parse-address');
const SOURCE = 'helpers';
import Config from '../utils/Config';

/** Add listener for the enter key - Only needed for non-buttons, links, etc. */
export const addEnterListener = e => {
  if (e.keyCode === 13) {
    e.target.click();
  }
};

/** Checks a string for any variation of a PO Box */
export const containsPOBox = address => {
  if (
    /^ *((#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i.test(
      address
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const toUpperCase = data => {
  return data ? data.toUpperCase() : null;
};

//Every time component loads,moves the scrollbar to top
export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

// function to handle keydown events
export const onSelectKeyDown = e => {
  if (e.keyCode === 13 || e.keyCode === 32) {
    e.target.click();
  }
};

export const isProd = () => {
  return (
    EnvConfigs.REACT_APP_IS_PROD === true ||
    EnvConfigs.REACT_APP_IS_PROD === 'true'
  );
};

export const isCorpDevice = device => {
  return (
    device.LocationGroupName &&
    device.LocationGroupName.replace(/\s+/g, Consts.EMPTY) ===
      Consts.DEVICE_OWNERSHIPS.CORPORATE.replace(/\s+/g, Consts.EMPTY)
  );
};

export const getAdditionalInfoByName = (details, detailName) => {
  const filteredDetails = details.filter(item => {
    return item.name === detailName;
  });

  return filteredDetails.length ? filteredDetails[0].value : '';
};

export const setLocalStorageItemWithTTL = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getDigitalTwinTechBarLocations = async (
  setIsLoading,
  setTechBarLocations
) => {
  let techBarLocationsStorageData = localStorage.getItem(
    'techBarLocationsStorageData'
  );
  if (techBarLocationsStorageData === null) {
    techBarLocationsStorageData = await fetchDigitalTwinTechBarLocations(
      setIsLoading
    );
    setTechBarLocations(techBarLocationsStorageData);
  } else {
    setTechBarLocations(JSON.parse(techBarLocationsStorageData));
    setIsLoading(false);
  }
};

export const getLocalStorageItemWithTTL = key => {
  const jsonStr = localStorage.getItem(key);

  if (!jsonStr) {
    return null;
  }

  const item = JSON.parse(jsonStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const clearUserItemsInLocalStorage = () => {
  localStorage.removeItem(APIs.USER_DATA);
  localStorage.removeItem(APIs.VDI_DATA);
  localStorage.removeItem(APIs.DEVICE_DATA);
};

export const getImageByName = (images, name) => {
  return (
    images &&
    images.filter(item => {
      return item.id === name;
    })[0]
  );
};

// 2 sep func for read img and pdf
export const readDataStream = async (containerName, prefix, blobName) => {
  try {
    return await axios
      .get(APIs.getAPIUrl(APIs.FETCH_STORAGE_DATA), {
        params: {
          containerName: containerName,
          blobName: blobName,
          prefix: prefix,
        },
        headers: { ignoreGenericError: true, ignoreNetworkError: true },
      })
      .then(response => {
        if (response.data) {
          return response.data;
        }
        trackEvent(
          'Invalid response from containerName: ',
          containerName,
          ' blobName: ',
          blobName,
          ' response: ',
          response
        );
        return {};
      })
      .catch(err => {
        ErrorHandler.trackError(
          Events.ERRORS.TITLE.READ_BLOB_STORAGE_DATA,
          err
        );
      });
  } catch (err) {
    ErrorHandler.trackError(Events.ERRORS.TITLE.READ_BLOB_STORAGE_DATA, err);
  }
};

export const uploadStorageFile = (file, blobName) => {
  try {
    axios.post(APIs.getAPIUrl(APIs.UPLOAD_STORAGE_DATA), file, {
      params: {
        containerName: Consts.APPSTORE_CONTAINER,
        blobName: `${Consts.LICENSE_ICON_FOLDER}/${blobName}`,
      },
    });
  } catch (err) {
    ErrorHandler.trackError(Events.ERRORS.TITLE.UPLOAD_BLOB_STORAGE_DATA, err);
  }
};

export const readPDF = async (containerName, prefix, blobName) => {
  try {
    const response = await readDataStream(containerName, prefix, blobName);
    const byteCharacters = atob(response);
    const encodedBytes = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      encodedBytes[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(encodedBytes);
    const file = new Blob([byteArray], { type: 'application/pdf;base64' });
    return URL.createObjectURL(file);
  } catch (error) {
    ErrorHandler.trackError(
      Events.ERRORS.TITLE.FETCH_PDF_DATA_BLOB_STORAGE,
      error
    );
  }
};

export const readImages = async (containerName, prefix, blobName) => {
  try {
    return await readDataStream(containerName, prefix, blobName);
  } catch (error) {
    ErrorHandler.trackError(
      Events.ERRORS.TITLE.FETCH_IMAGE_DATA_BLOB_STORAGE,
      error
    );
  }
};

export const isTablet = modelName => {
  return /(ipad|tablet)/i.test(modelName);
};

/**
 * Function used to return timer for setting the
 * feedBackDelay boolean inorder to open the Feedback dialog
 * @param {func} setFeedBack
 * @param {number} feedbackDelay
 * @returns
 */
export const feedbackTimer = (setFeedBack, feedbackDelay) => {
  return setTimeout(() => {
    setFeedBack(true);
  }, calculateTimeoutSeconds(feedbackDelay));
};

export const removeDisabledTabs = tabs => {
  return tabs.filter(tab => !tab.disabled);
};

export const findIndexByTitle = (tabs, tabTitle) => {
  return tabs.findIndex(tab => tab.title === tabTitle);
};

export const getTabIndex = (tabs, tabTitle, defaultIndex = 0) => {
  const tabIndex = findIndexByTitle(tabs, tabTitle);
  return tabIndex !== -1 ? tabIndex : defaultIndex;
};

export const logUtil = (logLevel, source, step, additionalContext) => {
  const log = {
    level: logLevel,
    source,
    step,
  };
  if (additionalContext) {
    log.context = additionalContext;
  }

  console.log(JSON.stringify(log));
  return log;
};

export const convertUSStateCodeToFullName = stateCode => {
  if (!stateCode) {
    return Consts.EMPTY;
  }

  const state = Consts.STATE_DATA.find(
    element => element.code === stateCode.toUpperCase()
  );
  return state && state.value ? state.value : Consts.EMPTY;
};

export const getOSLabel = path => {
  if (!path) {
    return '';
  }
  if (path.startsWith(`${Routes.HELP_CENTER}/`)) {
    const pathArr = path.split('/');
    if (
      pathArr.length > 1 &&
      [Consts.MAC, Consts.WINDOWS].includes(pathArr[2])
    ) {
      return capitalizeFirstLetter(pathArr[2]);
    } else if (pathArr.length > 1 && pathArr[2] === Consts.TECH_BAR_LOCATIONS) {
      return Consts.HELP_CENTER_TECH_BAR_LOCATIONS.TITLE;
    }
  }
  return '';
};

export const sortBy = field => {
  return (a, b) => {
    if (a[field] < b[field]) {
      return -1;
    } else if (a[field] > b[field]) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const removeTags = str => {
  return str.replace(/(<([^>]+)>)/gi, '');
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const daysSinceLastLogin = (time, today) => {
  //Check if last connection time is zero
  if (
    Date.parse(time) === Date.parse('0001-01-01T00:00:00') ||
    Date.parse(time) === Date.parse('0001-01-01T00:00:00Z')
  ) {
    return 0;
  }
  return Math.floor((today - Date.parse(time)) / (1000 * 60 * 60 * 24));
};

export const truncateTwoDecimals = num => {
  return Math.round(num * 100) / 100;
};

export const includesIgnoreCase = (array, match) => {
  const result = array.find(element => {
    return element.toLowerCase() === match.toLowerCase();
  });
  return result ? true : false;
};

export const isVendor = adInfoType => {
  return adInfoType === 'V';
};

export const isAdminRightsExpired = (
  dateCreated,
  todaysDate,
  durationInDays
) => {
  const dateAdminRightsExpire = new Date(dateCreated);
  dateAdminRightsExpire.setDate(
    dateAdminRightsExpire.getDate() + durationInDays
  );
  return dateAdminRightsExpire < todaysDate;
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const filterShipmentTrackerData = (
  shipmentTrackerUserData,
  currentFilterValuesList
) => {
  if (shipmentTrackerUserData && currentFilterValuesList) {
    var filteredData = shipmentTrackerUserData;
    for (var i = 0; i < currentFilterValuesList.length; i++) {
      filteredData = filteredData.filter(order => {
        return (
          currentFilterValuesList[i].filterCategories.includes('All') ||
          currentFilterValuesList[i].filterCategories.includes(
            order[currentFilterValuesList[i].id]
          )
        );
      });
    }
    return filteredData;
  }
  return shipmentTrackerUserData;
};

export const getFilters = (filterCategory, shipmentTrackerUserData) => {
  return [
    'All',
    ...new Set(
      shipmentTrackerUserData.map(order => {
        const currentType = order[filterCategory]?.toLowerCase();
        const currentTypeSplitWords = currentType
          ?.split(' ')
          .map(type => type.charAt(0).toUpperCase() + type.slice(1));
        const modifiedCurrentType = currentTypeSplitWords?.join(' ');
        return modifiedCurrentType;
      })
    ),
  ]
    .filter(x => x)
    .map(filter => {
      return { id: filter, displayName: filter };
    });
};

export const toFormattedDate = date => {
  return date.toLocaleDateString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

export const getStoreClubDeviceModel = (businessUnit, options) => {
  return options.find(option => option.businessUnit === businessUnit)
    ?.deviceModel;
};

export const getStoreClubDeviceType = (businessUnit, options) => {
  return options.find(option => option.businessUnit === businessUnit)
    ?.deviceType;
};

export const getDeviceModelOptions = (businessUnit, deviceOptions) => {
  const deviceModel = deviceOptions.find(
    option => option.businessUnit === businessUnit
  )?.deviceModel;

  if (Array.isArray(deviceModel)) {
    return deviceModel.map(option => {
      return { value: option };
    });
  } else {
    return [{ value: deviceModel }];
  }
};

export const stateAbbreviationToFullName = abbreviation => {
  return Consts.STATE_DATA.find(state => state.code === abbreviation).value;
};

export const getOSName = (deviceData, selectedModel) => {
  const MacInformation = deviceData?.MacInformation;
  const PCInformation = deviceData?.PCInformation;
  if (MacInformation?.length || PCInformation?.length) {
    const [model, serialNumber] = selectedModel.trim().split(' - ');
    const isMacModel = MacInformation?.some(
      mac => mac.Model === model && mac.SerialNumber === serialNumber
    );
    const isPCModel =
      !isMacModel &&
      PCInformation?.some(
        pc => pc.Model === model && pc.WorkstationName === serialNumber
      );
    if (isMacModel) {
      return MacInformation[0]?.OSName;
    } else if (isPCModel) {
      return PCInformation[0]?.OSName;
    }
  }
};

export const isMacOs = computerNameOrModel => {
  return computerNameOrModel?.toLowerCase().includes(Consts.DEVICE_TYPES.MAC);
};

export const getIsTechSupportEnabled = () => {
  return Config.isSubmitATicketEnabled && !UserType.isIDCUser;
};

export const getTechSupportLink = () => {
  const isTechSupportEnabled = getIsTechSupportEnabled();
  if (isTechSupportEnabled) {
    return Routes.TECHSUPPORT;
  } else {
    return Routes.TICKETS_SUBMIT;
  }
};

export const overwriteAddress = (
  storeLocationData,
  isManualInput,
  storeLocationDataError
) => {
  return !isManualInput && (storeLocationDataError || !storeLocationData);
};

export const filterSearchLocations = (
  techBarLocations,
  searchTxt,
  isOpenLocationsChecked
) => {
  searchTxt = searchTxt.trim().toLowerCase();
  return techBarLocations.filter(location => {
    // just parsing state code into state name and adding the state name to parsed location object to check state name internally
    const parsedLocation = parser.parseLocation(location.description.location); //https://www.npmjs.com/package/parse-address

    const parsedStateName = states.getStateNameByStateCode(
      parsedLocation?.state //https://www.npmjs.com/package/us-state-codes
    );
    parsedLocation.stateName = parsedStateName;
    const updatedLocation = Object?.values(parsedLocation)?.join(' '); //converting back the parsed location object to string here.
    if (searchTxt.length === 0 && !isOpenLocationsChecked) {
      return true;
    }
    const isSearchTxtInLocationName = location?.name
      ?.toLowerCase()
      .includes(searchTxt);
    const isSearchTxtInLocationAddress = updatedLocation
      ?.toLowerCase()
      .includes(searchTxt);
    const isOpen = location?.enable_away_state === 'false';
    if (isOpenLocationsChecked) {
      return (
        (isSearchTxtInLocationAddress || isSearchTxtInLocationName) && isOpen
      );
    }
    return isSearchTxtInLocationAddress || isSearchTxtInLocationName;
  });
};

export const isHostHealthy = async host => {
  try {
    const noHeaders = axios.create();
    const response = await noHeaders.get(`${host}/api/health`, {
      timeout: 2000,
    });
    return response?.status === 200;
  } catch {
    return false;
  }
};

export const userLookup = async searchCriteria => {
  const response = await axios.get(APIs.getAPIUrl(APIs.SEARCH_DATA), {
    headers: {
      mode: 'cors',
      'Content-Type': 'application/json',
      accessControlMaxAge: 1000,
      ignoreGenericError: true,
    },
    params: {
      searchCriteria: searchCriteria,
      users: true,
      lists: false,
      contacts: false,
      publicFolders: false,
      searchText: searchCriteria,
      type: 'mailenabled',
      upn: UserType.userPrincipalName,
    },
  });
  logUtil(
    Consts.LOG_TYPES.INFO,
    SOURCE,
    'userLookup called for searchCriteria - ',
    searchCriteria
  );
  return response?.data;
};

export const getReplaceDeviceStatus = deviceData => {
  // validates if user has any corporate devices by matching LocationGroupName value here
  let corporateDevices = [];
  if (
    deviceData?.AirWatchInformation &&
    deviceData?.AirWatchInformation.length > 0
  ) {
    corporateDevices = deviceData?.AirWatchInformation.filter(device => {
      return (
        device?.LocationGroupName &&
        (device?.LocationGroupName === Consts.DEVICE_OWNERSHIPS.CORPORATE ||
          device?.LocationGroupName === Consts.DEVICE_OWNERSHIPS.CORPORATE_DC ||
          device?.LocationGroupName ===
            Consts.DEVICE_OWNERSHIPS.CORPORATE_STORE ||
          device?.LocationGroupName === Consts.DEVICE_OWNERSHIPS.CORPORATE_SAMS)
      );
    });
  }
  return corporateDevices && corporateDevices.length > 0;
};

export const getCurrentPhoneNumber = airWatchData => {
  const corpPhones = airWatchData.filter(
    device =>
      (device?.LocationGroupName === Consts.DEVICE_OWNERSHIPS.CORPORATE ||
        device?.LocationGroupName === Consts.DEVICE_OWNERSHIPS.CORPORATE_DC ||
        device?.LocationGroupName ===
          Consts.DEVICE_OWNERSHIPS.CORPORATE_STORE ||
        device?.LocationGroupName ===
          Consts.DEVICE_OWNERSHIPS.CORPORATE_SAMS) &&
      !device.Model.includes(Messages.I_PAD)
  );
  if (corpPhones.length > 0) {
    return corpPhones.reduce((a, b) => (a.LastSeen > b.LastSeen ? a : b))
      .PhoneNumber;
  }
  return null;
};

export const getCorpOrderAreaCode = (
  isReplacementOrder,
  currentDevicePhoneNumber,
  formAreaCode
) => {
  return isReplacementOrder
    ? String(currentDevicePhoneNumber).slice(0, 3)
    : formAreaCode;
};

export const getInternational = (
  isReplacementOrder,
  verizonInternational,
  isVerizonSelected,
  isATTSelected,
  isTMobileSelected
) => {
  if (
    !isReplacementOrder &&
    isVerizonSelected &&
    verizonInternational.length > 0
  ) {
    return true;
  } else if (!isReplacementOrder && isVerizonSelected) {
    return false;
  } else {
    return isATTSelected || isTMobileSelected;
  }
};

export const copyTextToClipboard = async (text, callback) => {
  if ('clipboard' in navigator) {
    if (callback) {
      callback();
    }
    return await navigator.clipboard.writeText(text);
  } else {
    if (callback) {
      callback();
    }
    return document.execCommand('copy', true, text);
  }
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const isPrintableCharacter = keyCode => {
  return (
    (keyCode > 47 && keyCode < 58) || // number keys
    keyCode === 32 ||
    keyCode === 13 || // spacebar & return key(s) (if you want to allow carriage returns)
    (keyCode > 64 && keyCode < 91) || // letter keys
    (keyCode > 95 && keyCode < 112) || // numpad keys
    (keyCode > 185 && keyCode < 193) || // ;=,-./` (in order)
    (keyCode > 218 && keyCode < 223)
  ); // [\]' (in order)
};

// Code for formatting phone number
export const formatPhoneNumber = phoneNumber => {
  // remove special characters and spaces
  let formattedPhoneNumberString = String(phoneNumber)
    .trim()
    .replace(/\D/g, '');

  // validate a country code wasn't added
  if (formattedPhoneNumberString.length > 10) {
    formattedPhoneNumberString = formattedPhoneNumberString.slice(-10);
  }

  return formattedPhoneNumberString;
};
